/* Modal styles */
.modal-dialog {
  @apply relative;

  /* Ensure modal is centered */
  margin: 1.75rem auto;

  /* Add backdrop blur when modal is open */
  backdrop-filter: blur(4px);

  /* Basic modal styling */
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);

  /* Ensure proper z-index */
  z-index: 1050;
}

.custom-modal {
  /* Add padding around modal content */
  @apply p-4;

  /* Modal header styling */
  .modal-header {
    @apply flex items-center justify-between p-4 border-b;

    h3 {
      @apply text-xl font-semibold text-gray-900;
    }
  }

  /* Modal body styling */
  .modal-body {
    @apply p-4 space-y-4;
    max-height: calc(100vh - 210px);
    overflow-y: auto;
  }

  /* Modal footer styling */
  .modal-footer {
    @apply p-4 border-t;
  }

  /* Close button styling */
  .btn-close {
    @apply text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center;
  }
}

/* Modal backdrop */
.modal-backdrop {
  @apply fixed inset-0 bg-black bg-opacity-50 transition-opacity;
}
