@use "@angular/cdk" as cdk;
@include cdk.overlay();
@import "@angular/cdk/overlay-prebuilt.css";
@tailwind base;
@tailwind components;
@tailwind utilities;


@import "quill/dist/quill.snow.css";

@font-face {
  font-family: SegoeIcons;
  src: url("/assets/Segoe Fluent Icons.ttf");
}
@import "@angular/cdk/overlay-prebuilt.css";


:root {
  --primary-light: rgba(255, 126, 0, 0.15);
  --active-blue: #0366a5;
  --header-grey: #f4f4f4;
  --border-grey: #ebebeb;
  --screen-xs: 375px;
  --screen-sm: 640px;
  --screen-md: 768px;
  --screen-lg: 1024px;
  --screen-xl: 1280px;
  --screen-2xl: 1536px;
}

html,
body {
  font-family: "Inter";
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 14px;
}
/* Buttons */
.btn-default {
  @apply text-gray-700 bg-white border border-[#0000003D] hover:bg-gray-100 focus:ring-[#0000003D] shadow-md font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none focus:ring-2  items-center;
}

.btn-default:disabled {
  @apply bg-gray-200;
}

.btn-default.primary {
  @apply text-white border-0 bg-primary hover:bg-lighter focus:ring-darker;
}
.btn-default.primary:disabled {
  @apply bg-darker;
}

.btn-default.small {
  @apply px-2 py-2 text-xs;
}
/* Buttons */

button:disabled {
  @apply cursor-not-allowed;
}

/* Inputs */
input[type="text"],
input[type="password"],
input[type="number"],
input[type="email"] {
  @apply bg-gray-50 border border-[#0000003D] text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5 outline-none;
}
/* Inputs */

label {
  @apply block mb-2 text-sm font-medium text-gray-900;
}

.menu-item {
  @apply inline-flex w-full px-4 py-2 text-sm text-gray-700 hover:bg-lighter hover:text-white text-nowrap;
}

.card-image {
  @apply w-full h-36 rounded-t-xl object-cover;
}

.listing-container {
  @apply grid gap-6 sm:grid-cols-[repeat(auto-fill,minmax(200px,1fr))];
}

textarea {
  @apply block w-full text-sm text-gray-900 bg-gray-100 rounded-lg border border-[#0000003D] focus:ring-primary focus:border-primary   p-2.5 outline-none;
}

.chip {
  @apply h-8 text-sm px-2 py-1 border border-[#0000003D] bg-gray-100 rounded-md flex items-center gap-1;
}

.active-tab {
  @apply inline-block p-4 text-darker border-b-2 border-darker rounded-t-lg;
}

.inactive-tab {
  @apply inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300;
}

.playbook-nav-btn {
  @apply rounded-xl bg-lighter hover:bg-darker py-1 px-2;
}

.search-highlight {
  @apply bg-primary text-white;
}

.cdk-drag-preview {
  border: none;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow:
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.drag-drop-container.cdk-drop-list-dragging
  .drag-drop-item:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
.playbook-listings{
  position: relative;
}
swiper-container {
  width: 100%;
  height: 100%;
}

swiper-slide {
  display: flex;
  border-radius: 10px;
}

swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-button-next{
  right: -18px;
}

.swiper-card-image {
  width: 245px;
  height: 11rem;
  object-fit: cover;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}
.swiper-container {
  position: relative;
}

.swiper-container .cursor-pointer{
  border-radius: 10px;
  background: #fff;
}
.playbook-card{
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  position: relative;
}
.playbook-card h2 {
  height: 54px;
}

swiper-container {
  &::part(button-next),
  &::part(button-prev) {
    padding: 10px 15px;
    border-radius: 50%;
    background-color: #fff;
    color: #000;
    font-size: 25px;
  }
}

.\!bg-primary{
  color: white !important;
}

.cdk-overlay-container{
  z-index: 2000;
}
